<template>
    <div class="mb-5">
        <div class="simulate-title">{{ $t('STREAMING PREVIEW') }}</div>
        <div class="container">
            <video-player :options="videoOptions"/>
        </div>
    </div>
</template>

<script>
    import VideoPlayer from "../components/video/VideoPlayer";

    export default {
        name: "SimulateScreen",
        components: {VideoPlayer},
        data() {
            return {
                videoOptions: {
                    autostart: true,
                    file: "https://ext.inisoft.tv/demo/BBB_clear/hls/demo.m3u8"
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .simulate-title {
        font-size: 1.5em;
        padding: 2em 0;
        background-color: rgba(245, 245, 245, 100);
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 2em
    }
</style>